import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesConfig } from './core/defines/pages.config';
import { commonService } from './core/services/common.service';
import { noAuthGuard } from './core/guards/auth.guard';

const common = new commonService();

const routes: Routes = [
  {
    path: '',
    redirectTo: PagesConfig.home,
    pathMatch: 'full',
  },
  {
    path: PagesConfig.home,
    loadChildren: () =>
      import('./site/landing-page/template-one/layout-template-one/layout-template.module').then(
        (m) => m.LayoutTemplateModule
      ),
  },
  {
    path: PagesConfig.booking,
    loadChildren: () => import('./site/site.module').then((m) => m.SiteModule),
  },
  {
    path: PagesConfig.welcome,
    loadChildren: () =>
      import('./site/feature-modules/welcome/welcome.module').then(
        (m) => m.WelcomeModule
      ),
    canActivate: [noAuthGuard],
  },
  {
    path: PagesConfig.login,
    loadChildren: () =>
      import('./site/feature-modules/login/login.module').then(
        (m) => m.LoginModule
      ),
    canActivate: [noAuthGuard],

  },
  {
    path: PagesConfig.notFound,
    loadChildren: () =>
      import('./site/feature-modules/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
