import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { commonService } from '../services/common.service';
import { DeviceUUID } from 'device-uuid';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService, private common: commonService) { }

  uuid = new DeviceUUID().get();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('i18n')) {
      return next.handle(request);
    }
    return from(this.getCompanyIdentifier()).pipe(
      switchMap(companyIdentifier => {
        const modifiedRequest = request.clone({
          setHeaders: {
            'AppId': '6',
            'lang': this.translate?.currentLang??'ar',
            'DeviceType': 'bookingLink',
            'DeviceId': this.uuid,
            'CompanyIdentifier': companyIdentifier,
            ...this.common.customHeader
          }
        });
        return next.handle(modifiedRequest);
      })
    );
  }

  private getCompanyIdentifier(): Promise<string | null> {
    return new Promise((resolve) => {
      const companyIdentifier = localStorage.getItem('companyIdentifier');
      resolve(companyIdentifier);
    });
  }
}
