export enum ItemPlace {
  OnSite = 0,
  Home = 2,
  Both = 1,
}

export enum AppointmentPlace {
  Place = 0,
  Home = 1,
}
