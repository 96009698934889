import { CanActivateFn, Router } from '@angular/router';
import { commonService } from '../services/common.service';
import { inject } from '@angular/core';

export const noAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(commonService);
  const router = inject(Router);
  if (authService.isLoggedIn()) {
    router.navigate(['/home']);
    return false;
  } else {
    return true;
  }
};
