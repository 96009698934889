import { Directive, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Directive({
  selector: '[appComponentBase]',
})
export abstract class ComponentBase implements OnDestroy {
  constructor() {}
  checkResponse!: boolean;
  destroy$ = new Subject<void>();
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
