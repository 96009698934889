import { Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { commonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly common: commonService,
    private route: Router
  ) {}
  getHeaders() {
    const token: string | null = localStorage.getItem('GlameraBLToken') || null;
    const language: string = localStorage.getItem('lang')
      ? String(localStorage.getItem('lang'))
      : 'en';
    
    if (token) {
      return {
        headers: new HttpHeaders()
          .set('lang', language)
          .set('BranchId', this.common.getBranch()?.id.toString() ?? '')
          .set('Authorization', 'Bearer ' + token),
      };
    }
    return { headers: new HttpHeaders()
      .set('lang', language)
      .set('BranchId', this.common.getBranch()?.id.toString() ?? '')
     };
  }

  public get<TResponse>(type: string, params?: any): Observable<TResponse> {
    this.RemoveNull(params);
    
    return this.http
      .get<TResponse>(environment.apiURL + type, {
        params: { ...params },
        ...this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Observable<never> {
    console.log(error);

    if (error.status === 401) {
      this.route.navigate([`login`]);
    }

    // Re-throw the error so that it can be caught by the subscriber
    return throwError(error);
  }

  RemoveNull(queryParams: any) {
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        if (
          queryParams[key] == null ||
          queryParams[key] == undefined ||
          queryParams[key] == 'null' ||
          queryParams[key] == 'undefined'
        )
          delete queryParams[key];
      });
    }
    return queryParams;
  }

  public post<TInput, TResponse>(
    type: string,
    data: TInput
  ): Observable<TResponse> {
    return this.http
      .post<TResponse>(environment.apiURL + type, data ?? {}, {
        ...this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  public put<TInput, TResponse>(
    type: string,
    data: TInput,
    params?: any
  ): Observable<TResponse> {
    return this.http
      .put<TResponse>(environment.apiURL + type, data, {
        params: { ...params },
        ...this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  public delete<TInput, TResponse>(
    type: string,
    id?: TInput
  ): Observable<TResponse> {
    if (id) {
      return this.http
        .delete<TResponse>(environment.apiURL + type + id, {
          ...this.getHeaders(),
        })
        .pipe(catchError((error) => this.handleError(error)));
    } else {
      return this.http
        .delete<TResponse>(environment.apiURL + type, {
          ...this.getHeaders(),
        })
        .pipe(catchError((error) => this.handleError(error)));
    }
  }
}
