import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { ApiService } from 'src/app/core/services/api.service';
import { ICompanyInfo } from '../models/welcome.models';
import { commonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  constructor(private readonly apiService: ApiService, private common:commonService) {}

  getCompanyInfo(): Observable<ApiResponse<ICompanyInfo>> {
    return this.apiService.get<ApiResponse<ICompanyInfo>>('/company/info', {companyIdentifier: this.common.getCompanyIdentifier()});
  }
}
