import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ItemPlace } from 'src/app/site/shared-components/item-location-overlay/models/model';
import { IBranch } from 'src/app/site/feature-modules/search-branch/models/branch.model';
import { TranslateService } from '@ngx-translate/core';
import { ICompanyInfo } from 'src/app/site/feature-modules/welcome/models/welcome.models';
import { PagesConfig } from '../defines/pages.config';
import { Router } from '@angular/router';
export function detectEnv() {}

@Injectable({
  providedIn: 'root',
})
export class commonService {
  isVisitor: boolean = true;
  selectedPlace: ItemPlace | null = null;
  customHeader: any;
  itemPlace$: Subject<ItemPlace> = new Subject<ItemPlace>();
  branch$: Subject<IBranch> = new Subject<IBranch>();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  error$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchItems$: Subject<string> = new Subject<string>();
  companyInfo$: BehaviorSubject<ICompanyInfo | null> = new BehaviorSubject<ICompanyInfo | null>(null);
  selectedBranch: IBranch | null = null;
  constructor(private translate?: TranslateService, private router?: Router) {}

  get isLoading(): boolean {
    return this.loading$.getValue();
  }

  get hasError(): boolean {
    return this.error$.getValue();
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }
  getCompanyIdentifier(): string {
    const companyIdentifier = localStorage.getItem('companyIdentifier');
    if (companyIdentifier) {
      return companyIdentifier;
    }
    return '';
  }

  setCompanyIdentifier(companyIdentifier: string): void {
    localStorage.setItem('companyIdentifier', companyIdentifier);
  }


  setUserName(userName: string): void {
    localStorage.setItem('userName', userName);
  }

  getUserName(): string | null {
    let userName: string | null = localStorage.getItem('userName');
    if (userName === null) {
      return '';
    }
    return userName;
  }

  setEmail(email: string): void {
    localStorage.setItem('email', email);
  }
  getEmail(): string | null {
    let email: string | null = localStorage.getItem('email');
    if (email === null) {
      return '';
    }
    return email;
  }

  setToken(token: string): void {
    localStorage.setItem('GlameraBLToken', token);
  }
  getToken(): string | null {
    let token: string | null = localStorage.getItem('GlameraBLToken');
    if (token === null) {
      return '';
    }
    return token;
  }

  setImage(image: string): void {
    localStorage.setItem('image', image);
  }
  getImage(): string | null {
    let email: string | null = localStorage.getItem('email');
    if (email === null) {
      return '';
    }
    return email;
  }

  setUserStatus(status: boolean): void {
    this.isVisitor = status;
  }
  getUserStatus(): boolean {
    return this.isVisitor;
  }

  setBranch(branch: IBranch | null): void {
    this.selectedBranch = branch;
   if(branch)
    this.branch$.next(branch);
  }
  getBranch(): IBranch | null {
    return this.selectedBranch;
  }
  clearBranch(): void {
    localStorage.removeItem('branch');
  }

  setItemPlace(place: ItemPlace) {
    this.selectedPlace = place;
    this.itemPlace$.next(place);
  }
  getItemPlace(): ItemPlace{
    return this.selectedPlace??ItemPlace.OnSite;
  }

  setLang(lang: string): void {
    this.translate?.use(lang);
    localStorage.setItem('lang', lang);
  }

  getLang(): string {
    let lang: any = localStorage.getItem('lang');
    if (lang === null) {
      return 'en';
    }
    return lang;
  }

  logOut(): void {
    localStorage.removeItem('GlameraBLToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    this.router?.navigate([PagesConfig.welcome]);
  }
}
