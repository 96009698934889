import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { commonService } from './core/services/common.service';
import { ICompanyInfo } from './site/feature-modules/welcome/models/welcome.models';
import { ClientDetails } from './site/feature-modules/profile/models/profile';
import { ComponentBase } from './core/base/common-base';
import { WelcomeService } from './site/feature-modules/welcome/services/welcome.service';
import { ItemPlace } from './site/shared-components/item-location-overlay/models/model';
import { IBranch } from './site/feature-modules/search-branch/models/branch.model';
import tinycolor from 'tinycolor2';
import { PagesConfig } from './core/defines/pages.config';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ComponentBase implements OnInit {
  loading: boolean;
  error: boolean;
  clientDetails: ClientDetails;
  companyInfo: ICompanyInfo;
  itemPlace: ItemPlace;
  branch: IBranch | null = null;
  changeLangSubscription = new Subscription();

  constructor(
    private router: Router,
    private config: PrimeNGConfig,
    private welcomeService: WelcomeService,
    private translate: TranslateService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private common: commonService,
    public rendererFactory: RendererFactory2,
    private cdr: ChangeDetectorRef,

  ) {
    super();
    this.renderer = rendererFactory.createRenderer(null, null);
    this.changeLangSubscription = this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (event: LangChangeEvent) => {
          localStorage.setItem('lang', event.lang);
          const dir = event.lang === 'en' ? 'ltr' : 'rtl';
          this.renderer.setAttribute(document.documentElement, 'lang', event.lang);
          this.renderer.setAttribute(document.documentElement, 'dir', dir);
        }
      );
  }

  ngOnInit(): void {
    this.setCompanyIdentifier();
    this.common.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
    this.common.error$.pipe(takeUntil(this.destroy$)).subscribe((error) => {
      this.error = error;
      this.cdr.detectChanges();
    });
  }
  setDefaultBranch(): void {

    this.common.setBranch({
      id: this.companyInfo.mainBranch.id,
      name: this.translate.currentLang == 'en' ? this.companyInfo.mainBranch.nameEn : this.companyInfo.mainBranch.nameAr,
      address: '',
      isMainBranch: false,
      logo: '',
      place: this.companyInfo.config.items.itemPlace,
      googleMapsLink: '',
      city: {
        id: 0,
        name: '',
      },
    });
  }

  private parseSubdomain(url: string): string {
    const parts: string[] = url.split('.');
    return parts.length > 2 || parts[1] == 'localhost' ? parts[0] : `${parts[0]}.${parts[1]}`;
  }

  setCompanyIdentifier() {

    const hostname: string = this.document.location.hostname;
    const companyIdentifier = this.parseSubdomain(hostname);
    let oldCompanyIdentifier = this.common.getCompanyIdentifier();

    if (companyIdentifier !== oldCompanyIdentifier) {
      this.common.setCompanyIdentifier(companyIdentifier);
      this.common.clearBranch();
    }
    this.getCompanyInfo();
  }

  getCompanyInfo() {
    this.common.loading$.next(true);
    this.welcomeService
      .getCompanyInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.companyInfo = response.data;
          this.common.loading$.next(false);
          if (response.data) {

            // set branch and place
            if (
              this.companyInfo?.config.items.itemPlace == ItemPlace.Home ||
              this.companyInfo?.config.items.itemPlace == ItemPlace.OnSite
            ) {
              this.common.setItemPlace(this.companyInfo.config.items.itemPlace);              
            }
            this.setDefaultBranch();
            console.log(this.branch, "set branch");

            // primary color
            this.setCSSVariable(
              '--color-primary',
              response.data?.config.identity.design.color ?? '#694ed6'
            );
            this.updatePrimaryColor(
              response.data?.config.identity.design.color ?? '#694ed6'
            );


            // default language
            const lang: string = localStorage.getItem('lang') ?? response.data.config.identity.design.defaultLanguage;
            localStorage.setItem('lang', lang);
            this.translate.use(lang);
            this.translate.get('primeng').subscribe(res => {
              this.config.setTranslation(res);
            });


            // google analytics and facebook pixel
            this.loadScriptTag(response.data.config.marketing.googleAnalyticsScript);
            this.loadScriptTag(response.data.config.marketing.facebookPixelScript);
          }
          this.itemPlace = this.common.getItemPlace();
          this.branch = this.common.getBranch();
          console.log(this.itemPlace, "itemPlace");
          this.common.companyInfo$.next(response.data);

        },
        error: (err) => {
          if (err.status == 404)
            return this.router.navigate([PagesConfig.notFound404]);
          return this.common.loading$.next(false);
        },
      });
  }
  setCSSVariable(variable: string, value: string): void {
    document.documentElement.style.setProperty(variable, value);
  }

  updatePrimaryColor(baseColor: string): void {
    const color = tinycolor(baseColor);
    const variations: any = {
      '50': color.clone().lighten(50).toHexString(),
      '100': color.clone().lighten(40).toHexString(),
      '200': color.clone().lighten(30).toHexString(),
      '300': color.clone().lighten(20).toHexString(),
      '400': color.clone().lighten(10).toHexString(),
      '500': color.clone().toHexString(),
      '600': color.clone().darken(10).toHexString(),
      '700': color.clone().darken(20).toHexString(),
      '800': color.clone().darken(30).toHexString(),
      '900': color.clone().darken(40).toHexString(),
      '950': color.clone().darken(50).toHexString(),
    };

    Object.keys(variations).forEach((key) => {
      this.setCSSVariable(`--color-primary-${key}`, variations[key]);
    });
  }


  loadScriptTag(script: string) {
    let scripts = this.extractScriptsAndNoScripts(script);
    scripts.scripts.forEach((scriptContent) => {
      this.loadScript(scriptContent);
    });
    scripts.noScripts.forEach((noScriptContent) => {
      this.loadNoScript(noScriptContent);
    });
  }

  loadScript(scriptContent: string) {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = scriptContent;
    this.renderer.appendChild(document.body, script);
  }

  loadNoScript(noScriptContent: string) {

    const noScript = this.renderer.createElement('noscript');
    noScript.text = noScriptContent;
    this.renderer.appendChild(document.body, noScript);
  }


  extractScriptsAndNoScripts(script: string): { scripts: string[], noScripts: string[] } {
    const scriptContents: string[] = [];
    const noScriptContents: string[] = [];

    const scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/gi;
    const noScriptRegex = /<noscript[^>]*>([\s\S]*?)<\/noscript>/gi;

    let match;

    while ((match = scriptRegex.exec(script)) !== null) {
      if (match[1]) {
        scriptContents.push(match[1].trim());
      }
    }

    while ((match = noScriptRegex.exec(script)) !== null) {
      if (match[1]) {
        noScriptContents.push(match[1].trim());
      }
    }

    return { scripts: scriptContents, noScripts: noScriptContents };
  }

}
