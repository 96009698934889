export const PagesConfig = {
  welcome: `welcome`,
  search: `search`,
  login: `login`,
  home: `home`,
  checkout: `checkout`,
  notFound: '**',
  notFound404: '404',
  editAccount: `editAccount`,
  profile: `profile`,
  success: `success`,
  paymentDone: `payment-done/:paymentReference`,
  resources: `resources`,
  aboutUs: `about-us`,
  termsAndConditions: 'terms-and-conditions',
  returnPolicy: 'return-policy',
};
